@media (max-width: 1100px) {
}
@media (max-width: 768px) {
  .form-flex-item {
    flex-basis: calc(50% - 16px);
  }
  .row {
    gap: 17px;
  }
  .Summarymaindiv {
    & .form-body {
      height: 16rem;
    }
  }
  // .styleLikeTd,.cartTableHeadingTh{
  //   min-width: 93px;
  //   & .CreatableSelect-input {
  //     width: 4rem;
  //   }
  // }
  .tableWidthOverflow {
    overflow: auto;
    background-color: white;
  }
  .SKU-width {
    padding-top: 2rem;
    width: 111px;
  }
  .dragAndDropMainDiv,
  .borderCartHeading {
    width: min-content;
  }
  .borderCartHeading {
    width: 80rem;
  }
}
@media screen and (max-width: 570px) {
  .Dashboard-image{
    & img{
      width: 100%;
    }
  }
  .form-flex-item {
    flex-basis: 100%;
  }
  .Responsive {
    flex-direction: column;
    & button {
      width: 100% !important;
    }
  }
}
@media screen and (max-width: 425px) {
  .Select-input ,.regionSelect {
    width: 100% !important;
  }
  .responsovivecart{
    padding: 0;
  }
  .responsovivecart-2{
    padding: 0.5rem !important;
  }
}

