@media (min-resolution: 120dpi) and (max-resolution: 130dpi) {
  /* .newSideheaderMain {
      zoom: 100%;
    } */
  .container,
  .containerForUser {
    zoom: 90%;
  }

  .pipelineclass {
    zoom: 115% !important;
  }

  /* .fullHeightLogin{
        zoom: 85%;
    }
    .fullHeightLogin .d-flex .align-items-center .justify-content-center .row{
        zoom: 100% !important;
    } */

  .modal-body {
    zoom: 85%;
  }

  .cart-sidebar-open-v2 {
    max-width: 1400px !important;
  }

  .crm_padding_none,
  .crm_padding_email_none_imp {
    zoom: 100% !important;
  }

  .crm_padding_email_none_imp div{}


}
