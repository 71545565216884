/* @import url('../Fonts/family_Lato_300_400.css'); */
/* @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600|Open+Sans&display=swap'); */
@import url('../Fonts/Inter.css');
@import 'variables.scss';
body {
  /* font-family: 'Montserrat', 'Open Sans', sans-serif; */
  font-family: 'Inter', sans-serif !important;
}

:root {
  --light-blue: #22ced6;
  --dark-blue: #1aafb5;
  --green: #07ce41;
  --dark-purple: #3f0a87;
  /* --dark-purple: #753CB7; */
  --darker-purple: #320466;
  /* --darker-purple: #9A6ECC; */
  --light-grey: #8892a6;
  --medium-grey: #505050;
  --background: #efefef;
  --transparent-blue: rgba(175, 229, 255, 0.51);
  --btn-dark-purple: #753cb7;
  --btn-light-purple: #9a6fcc;
}

html * {
  font-family: 'Inter' !important;
  font-style: normal !important;
}

.cursor-pointer {
  cursor: pointer;
}

h1 {
  padding: 0;
  margin: 0;
}

table th {
  font-weight: 500;
}

table td {
  font-weight: 400;
}

.btn:focus,
.btn:active,
input:focus,
select:focus {
  box-shadow: none !important;
  /* border: 1px solid #753cb7 !important; */
  border-radius: 4px;
}

input {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

input:hover {
  /* border: 1px solid #bbbbbb !important; */
  border-radius: 4px;
  border-color: #bbbbbb !important;
}

.btn {
  font-weight: 300;
}

.btn.btn-light-blue {
  background-color: var(--light-blue);
  border-color: var(--light-blue);
  transition: all 0.15s ease-in-out;
}

.btn.btn-light-blue:hover {
  border-color: var(--dark-blue);
  background-color: var(--dark-blue);
}

.btn.btn-dark-purple {
  background-color: var(--dark-purple);
  border-color: var(--dark-purple);
  color: white;
  transition: all 0.15s ease-in-out;
}

.btnNoBackGround {
  background-color: #fff !important;
  border-color: var(--dark-purple) !important;
  color: var(--dark-purple) !important;
  transition: all 0.15s ease-in-out !important;
}

.btnNoBackGround:hover {
  border-color: var(--darker-purple) !important;
  background-color: var(--darker-purple) !important;
  color: white !important;
}

.btn.btn-dark-purple:hover {
  border-color: var(--darker-purple);
  background-color: var(--darker-purple);
}

.tippy-tooltip.light-theme {
  background-color: var(--light-blue);
}

.tippy-tooltip.light-theme .tippy-arrow {
  border-top: 8px solid var(--light-blue);
}

.contentWidth {
  max-width: calc(100% - 230px);
  transition: max-width 1s ease;
}

.icon {
  fill: currentColor;
}

.action-icon {
  color: #000;
  max-width: 24px;
  display: block;
  margin: 0 5px;
}

.action-icon img {
  width: 100%;
}

.select-none {
  user-select: none !important;
  pointer-events: none !important;
}

.full-height {
  height: 100vh;
  /* height: calc(100vh - 60px); */
  position: relative;
}

.date-picker-wrapper .react-datepicker-wrapper {
  width: 100%;
}

/* .h-100 {
  height: 100vh !important;
} */

.login label {
  width: 100px;
}

.btn-round {
  border-radius: 18px;
  padding-left: 15px;
  padding-right: 15px;
}

.app-wrapper {
  max-width: 100%;
  width: 100vw;
  min-height: 100vh;
}

.tippy-box[data-theme~='light'] {
  background-color: var(--light-blue);
  box-shadow: unset;
}

.tippy-box[data-theme~='light'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--light-blue);
}

.content-wrap {
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100%;
  position: relative;
  z-index: 5;
}

.app-wrapper .sidebar {
  width: 230px;
  background-color: #ffffff;
}

.sidebar .logo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 15px;
  color: var(--light-grey);
  margin-bottom: 1rem;
}

.sidebar .menu {
  color: var(--light-grey);
  display: block;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar .menu li {
  display: block;
}

/* .sidebar .menu li:first-child {
    border-top: 1px solid #707070;
} */

.sidebar .menu li a {
  color: var(--light-grey);
  display: inline-block;
  width: 100%;
  padding: 20px 15px;
  text-decoration: none !important;
}

.sidebar .menu li span {
  display: inline-block;
  line-height: 1;
  height: 20px;
  vertical-align: middle;
}

.sidebar .menu li span.icon {
  width: 20px;
  height: 20px;
  text-align: center;
  margin: 0 20px;
  filter: grayscale(100%);
}

.sidebar .menu li span.icon img {
  width: auto;
  height: 20px;
}

.sidebar .menu li a.menu-active {
  color: var(--dark-purple);
}

.sidebar .menu li a.menu-active span.icon {
  filter: none;
}

.header {
  background-color: white;
  /* height: 60px; */
  padding-right: 2rem;
  /* padding-left: 2rem; */
  top: 0;
}

@media screen and (max-width: 768px) {
  .header {
    padding-right: 0rem;
  }

  .profile-dropdown {
    margin-left: 1rem !important;
  }

  .FullWidthConent {
    padding-left: 0 !important;
  }
}

.content {
  width: calc(100% - 18rem);
}

.FullWidthConent {
  width: 100%;
}

.main {
  width: 100%;
  background-color: var(--background);
  padding: 0px 15px;
  height: calc(100vh);
  max-height: calc(100vh);
  /* height: calc(100vh - 60px); */
  /* max-height: calc(100vh - 60px); */
  overflow: auto;
}

/* .pdf-item .btn {
  font-size: 0.8rem;
} */

.pdf-details {
  color: var(--medium-grey);
}

.pdf-active {
  color: var(--green);
}

.pdf-deactive {
  color: red;
}

.pdf-details h3 {
  font-size: 1.4rem;
  font-weight: 500;
}

.pdf-details p {
  font-size: 1rem;
  line-height: 1rem;
  font-family: 'Inter', sans-serif !important;
}

.pdf-item .item-img {
  filter: drop-shadow(0px 2px 4px #dadada);
}

/* .pdf-item .item-img img {
  min-width: 200px;
  min-height: 200px;
  height: 200px;
  width: 200px;
} */
*/ .pdf-item .item-img span {
  display: block;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-position: top;
}
.pdf-item .collateral-title {
  font-weight: 600;
  font-size: 18px;
}
.pdf-details p {
  font-size: 14px;
  line-height: 17px;
}
.pdf-details span {
  font-size: 14px;

  line-height: 17px;
}
.pdf-item .item-action {
  width: 220px;
  text-align: center;
}

.card-fix {
  border-left: 3px solid var(--dark-purple);
  box-shadow: 0px 6px 5px -2px rgba(0, 0, 0, 0.2) !important;
  outline: 0;
  margin-top: 50px;
}

.btn-options {
  width: 350px;
  box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.15);
  background: #f9f9f9;
  position: relative;
}

.canvas-wrap {
  /* border: 1px solid #000; */
  /* background: #FFFFFF; */
  box-shadow: 0px 4px 50px #dfdfdf;
  position: relative;
  margin: 16px auto;
}

.draw-wrap {
  position: relative;
  z-index: 4;
}

.canvas-wrap img {
  position: absolute;
  width: 100%;
  z-index: -1;
}

.hot-spotx {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hot-spotx .hot-spot {
  position: absolute;
  z-index: 100;
  background: var(--transparent-blue);
  opacity: 0.7;
}

.hot-spotx .hot-spot:focus {
  outline: none;
  position: absolute;
  z-index: 5;
  opacity: 0.7;
  background: var(--transparent-blue);
}

.spot-option {
  height: 40px;
  width: auto;
}

.spot-option .spot-opt {
  display: inline-block;
  height: 40px;
  width: 40px;
  font-size: 12px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 50%;
}

.spot-option span + span {
  margin-left: 8px;
}

.option-header {
  height: 45px;
  margin: 0px -15px 20px;
  background-color: var(--light-grey);
  box-shadow: 0px 0px 7px -1px rgba(0, 0, 0, 0.6);
  padding: 0px 15px;
  line-height: 43px;
  color: #fff;
}

.spot-option .opt-wrap {
  width: 100%;
  height: auto;
  padding-bottom: 40px;
}

.opt-wrap label {
  user-select: none;
  font-size: 14px;
}

.opt-wrap input.form-control {
  height: 30px;
  line-height: 30px;
  padding: 4px 10px;
  font-size: 15px;
}

.opt-wrap input[type='checkbox'] {
  height: 16px;
  margin-left: 0;
  width: 16px;
}

.opt-wrap input[type='checkbox'] ~ label {
  margin-left: 22px;
  margin-bottom: 0;
}

.opt-wrap .dropdown > button {
  width: 100%;
  text-align: left;
  height: 35px;
  font-size: 14px;
  padding: 4px 10px;
}

.opt-wrap .dropdown > button:after {
  right: 15px;
  position: absolute;
  top: 50%;
}

.opt-wrap .dropdown .dropdown-menu {
  width: 100%;
}

.opt-wrap .dropdown .dropdown-menu > button:focus {
  background: #17a2b8;
}

.option-footer button {
  min-width: 90px;
  margin: 20px auto 30px;
}

.menu-builder {
  width: 100%;
  height: calc(100vh - 60px);
  max-height: calc(100vh - 90px);
  /* overflow-y: auto;
  overflow-x: hidden; */
  max-width: calc(100% - 350px);
  float: left;
}

.menu-handler {
  width: 100%;
  height: calc(100vh - 82px);
  max-height: calc(100vh - 90px);
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 350px;
  float: left;
  background: #f6f6f6;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  padding: 15px;
  position: relative;
}

.menu-handler h1 {
  font-size: 18px;
  margin: 0px;
  padding: 8px 0px 12px;
  border-bottom: 1px solid #000;
  line-height: 1;
}

.menu-builder-options {
  width: 100%;
  background: #f6f6f6;
  height: 60px;
  box-shadow: 0px -2px 5px 4px rgba(0, 0, 0, 0.2);
}

.menu-builder-playground {
  min-height: calc(100vh - 120px);
  width: 100%;
  /* height: 100%; */
}
.menu-builder-playground-2 {
  min-height: calc(100vh - 145px) !important;
  width: 100% !important;
  max-height: 90% !important;
  /* height: 100%; */
}

.menu-builder-playground .ReactVirtualized__Grid {
  padding: 50px 25px;
}

.menu-handler header {
  margin-bottom: 10px;
}

.menu-handler .selected-thumb .img-thumbnail {
  max-width: 260px;
}

.menu-handler label {
  font-size: 14px;
}

.menu-handler .form-group {
  margin-bottom: 25px;
}

.menu-handler .form-group .error-msg {
  color: red;
  position: absolute;
}

.menu-handler footer {
  position: fixed;
  bottom: 15px;
  width: 100%;
  text-align: center;
  max-width: 350px;
}

.submenu .dropdown-toggle {
  color: #8892a6;
  width: 100%;
}

.submenu .dropdown-toggle::after {
  content: '';
  display: none;
}

/* Menu Important Class */

.node-item .rst__rowContents .rst__rowTitle {
  font-weight: normal;
}
.rst__rowContents {
  /* width: 100rem !important; */
  background: #f5f5f5 !important;
  border-radius: 0.25rem !important;
  border: 0px solid var(--stroke, #e6e6e6) !important;
  /* background: #F5F5F5 ; */
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.node-item {
  background: #f5f5f5 !important;
  width: 60rem !important;
  height: 3.25rem !important;
}
.rst__toolbarButton {
  margin-left: 10px;
}
.rst__rowToolbar {
  margin-left: 20px;
  margin-right: 20px;
}
.node-item .rst__moveHandle,
.node-item .rst__loadingHandle {
  background: url('../drag-2.svg') no-repeat center;
  /* background: none; */
  color: #000;
  border: none !important;
  cursor: move !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.node-item .page_no {
  padding: 0px 5px;
  font-size: 12px;
  display: inline-block;
  line-height: 33px;
  font-weight: 500;
}

.node-del {
  height: 35px;
  width: 35px;
  line-height: 40px;
  padding: 0;
  border-radius: 4px;
}

.node-del svg {
  width: 20px;
  height: 20px;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  background-color: #e6e6e6 !important ;
}
.rst__collapseButton {
  background: #e6e6e6
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center !important;
}
.rst__collapseButton,
.rst__expandButton {
  box-shadow: 0 0 0 1px #e6e6e6 !important;
  -webkit-box-shadow: 0 0 0 1px #e6e6e6 !important;
}
.node-item .rst__moveHandle,
.node-item .rst__loadingHandle {
  border-radius: 0.25rem !important;
  border: 0px solid var(--stroke, #e6e6e6) !important;
}
/* End */
.onoffswitch {
  position: relative;
  width: 55px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 20px;
  padding: 0;
  line-height: 20px;
  border: 0px solid #ffffff;
  border-radius: 30px;
  background-color: #9e9e9e;
  margin-bottom: 0;
}

.onoffswitch-label:before {
  content: '';
  display: block;
  /* width: 30px;  */
  width: 25px;
  height: 25px;
  margin: -5px;
  background: #ffffff;
  position: absolute;
  top: 2px;
  bottom: 0;
  right: 36px;
  border-radius: 30px;
  box-shadow: 0 6px 12px 0px #757575;
}

.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: var(--dark-purple);
}

.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: var(--dark-purple);
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
  background-color: #ffffff;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
}

.hot-spotx.hideDivs .hot-spot {
  z-index: 0;
}

.active-div {
  border: 1px dashed rgba(0, 0, 0);
}

.addToCartButton {
  background-color: black;
  position: absolute;
  z-index: 5;
}

.div-style-btnTheme-1 span,
.div-style-btnType-0.div-style-btnTheme-1,
.div-style-btnType-1.div-style-btnTheme-1 {
  filter: invert(1);
}

.hot-spot > span {
  width: 100%;
  height: 100%;
}

.hot-spot.div-style-btnIcon-1 > span {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1000;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
  background-image: url('#{$public}/manage/images/btnIcons/btnIcon1.svg');
  background-repeat: no-repeat;
  background-size: 20px;
}

.hot-spot.div-style-btnIcon-2 > span {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1000;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
  background-image: url('#{$public}/manage/images/btnIcons/btnIcon2.svg');
  background-repeat: no-repeat;
  background-size: 20px;
}

.hot-spot.div-style-btnIcon-3 > span {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1000;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
  background-image: url('#{$public}/manage/images/btnIcons/btnIcon3.svg');
  background-repeat: no-repeat;
  background-size: 20px;
}

.hot-spot.div-style-btnIcon-4 > span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
  background-image: url('#{$public}/manage/images/btnIcons/btnIcon4.svg');
  background-repeat: no-repeat;
  background-size: 20px;
}

.hot-spot.div-style-btnIcon-5 > span {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  max-width: 20px;
  max-height: 20px;
  background-image: url('#{$public}/manage/images/btnIcons/btnIcon5.svg');
  background-repeat: no-repeat;
  background-size: contain;
}

.btn_size-0 span {
  max-width: 20px !important;
  max-height: 20px !important;
  background-size: 20px !important;
}

.btn_size-1 span {
  max-width: 30px !important;
  max-height: 30px !important;
  background-size: 30px !important;
}

.btn_size-2 span {
  max-width: 40px !important;
  max-height: 40px !important;
  background-size: 40px !important;
}

.btn_size-change-0 span {
  max-width: 20px !important;
  max-height: 20px !important;
  background-size: 20px !important;
}

.btn_size-change-1 span {
  max-width: 30px !important;
  max-height: 30px !important;
  background-size: 30px !important;
}

.btn_size-change-2 span {
  max-width: 40px !important;
  max-height: 40px !important;
  background-size: 40px !important;
}

.product-table thead {
  background-color: var(--light-blue);
  color: white;
}

.product-table tbody {
  background-color: #fff;
  color: #5f6369;
}

.product-table td,
.product-table tbody th {
  vertical-align: middle;
  font-weight: 400;
}

.productTableProductMaster thead tr th:nth-child(1) {
  width: 5%;
}

.productTableProductMaster thead tr th:nth-child(2) {
  width: 14%;
}

.productTableProductMaster thead tr th:nth-child(3) {
  width: 17%;
}

.productTableProductMaster thead tr th:nth-child(4) {
  width: 17%;
}

.productTableProductMaster thead tr th:nth-child(5) {
  width: 14%;
}

.productTableProductMaster thead tr th:nth-child(6) {
  width: 9%;
}

/* .product-table thead tr th:nth-child(7){
  width: 24%;
} */

.fileUpload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.file-info {
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.file-info .action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-btn {
  background: #fff url('../../../public/manage/images/SVG/publish.svg') center no-repeat;
}

.cross-btn {
  background: #fff url('../../../public/manage/images/SVG/close.svg') center no-repeat;
}

.download-btn,
.cross-btn {
  cursor: pointer;
  border: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  outline: none;
  background-size: contain;
  transition: all 0.2s ease-in-out;
}

.download-btn button {
  border: none;
  background: none;
}

.cross-btn:hover,
.download-btn:hover {
  filter: invert(1);
}

.upload-preview {
  position: relative;
}

.upload-preview span {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  font-size: 8px;
  padding: 2px 6px;
  background-color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  color: white;
}

.pwa-image-preview {
  height: 150px;
  width: 150px;
  position: relative;
}

.pwa-image-preview.dashed {
  border: 1.5px dashed rgba(0, 0, 0, 0.7);
}

.pwa-image-preview span {
  position: absolute;
  text-align: center;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.7);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.hot-spot.div-style-btnType-0 {
  background: #fff url('#{$public}/manage/images/SVG/cart.svg') center no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.hot-spot.div-style-btnType-1 {
  background: #fff url('#{$public}/manage/images/SVG/download.svg') center no-repeat;
  background-size: cover;
  border-radius: 50%;
}

.hot-spot.div-style-btnSize-0 {
  min-width: 10px !important;
  min-height: 10px !important;
  max-width: 30px !important;
  max-height: 30px !important;
}

.hot-spot.div-style-btnSize-1 {
  min-width: 10px !important;
  min-height: 10px !important;
  max-width: 50px !important;
  max-height: 50px !important;
}

.hot-spot.div-style-btnSize-2 {
  min-width: 10px !important;
  min-height: 10px !important;
  max-width: 60px !important;
  max-height: 60px !important;
}

/* #howItWorks {
  border-bottom: 2px solid var(--light-blue) !important;
  padding-bottom: 5px;
  width: auto !important;
} */

input,
input.form-control {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid var(--light-blue);
  background: none;
  padding-left: 5px;
  transition: all 0.3s ease-in-out;
}

.showRedBorder {
  border-radius: 0;
  border: none;
  border-bottom: 2px solid #dc3545 !important;
  background: none;
  padding-left: 5px;
  transition: all 0.3s ease-in-out;
}

input:focus,
input.form-control:focus,
textarea:focus {
  background: none;
  outline: none;
  /* border-bottom: 2px solid var(--dark-purple); */

  box-shadow: none !important;
  /* border: 1px solid #753cb7 !important; */
  border-radius: 4px;
}

.input-group.search-input-group .input-group-append {
  position: absolute;
  right: 0;
}

.input-group input[name='searchInput'] {
  padding-right: 40px;
}

.input-group.search-input-group .input-group-append span {
  background: none;
  border: none;
}

input:read-only,
input.form-control:read-only {
  border-radius: 0;
  border: none;
  padding-left: 10px;
}

input:read-only:focus,
input.form-control:read-only:focus {
  border: none;
}

.client-logo-preview {
  background-color: black;
  display: inline-block;
  max-width: 200px;
  max-height: 150px;
}

.client-logo-preview img {
  max-width: 200px;
  max-height: 150px;
}

.hot-spot p {
  margin-bottom: 0;
}

.ql-align-center {
  text-align: center;
}

.ql-align-right {
  text-align: right;
}

.ql-font-montserrat {
  font-family: 'Inter', sans-serif !important;
}

.ql-font-opensans {
  font-family: 'Inter', sans-serif !important;
}

.ql-size-small {
  font-size: 0.36rem;
}

.ql-size-large {
  font-size: 1.5rem;
}

.ql-size-huge {
  font-size: 2rem;
}

.page-no-counter {
  margin: 30px 0;
  display: flex;
  justify-content: center;
}

.page-no-counter > div {
  width: 23%;
  display: flex;
  justify-content: space-around;
  height: 40px;
  align-items: center;
}

.page-no-counter .number-input {
  text-align: center;
  border: none;
  width: 70%;
  background: white;
  height: 100%;
  outline: none;
  padding: 0;
  margin: 0;
}

.page-no-counter .number-input::placeholder {
  font-size: 1rem;
  letter-spacing: 1px;
  font-weight: lighter;
}

.arrow-btn {
  cursor: pointer;
  width: 15%;
  outline: none;
}

.arrow-btn img {
  width: 100%;
  height: auto;
}

.profile-dropdown {
  display: inline-block;
  margin-left: 1.6rem;
  margin-right: 1rem;
}

.profile-dropdown button:hover,
.profile-dropdown button:active,
.profile-dropdown button:focus {
  background-color: transparent !important;
}

.profile-dropdown .dropdown-toggle,
.profile-dropdown .dropdown-toggle:hover,
.profile-dropdown .dropdown-toggle:active,
.profile-dropdown .dropdown-toggle:disabled {
  background: none;
  border: none;
  font-size: 1.1rem;
  color: black;
  cursor: pointer;
}

.profile-dropdown.show > .btn-secondary.dropdown-toggle {
  background: none;
  border: none;
  font-size: 1.1rem;
  color: black;
}

.profile-dropdown .dropdown-item {
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.profile-dropdown a,
.profile-dropdown a:hover {
  text-decoration: none;
  color: black;
}

.img-placeholder {
  padding: 0.25rem;
  background-color: #fff;
  /* border: 1px solid #dee2e6; */
  /* border-radius: .25rem; */
  max-width: 100%;
  height: auto;
}

.cart-sidebar-open {
  position: fixed;
  max-width: 1250px;
  width: 100%;
  /* top: 50px; old */
  top: 0px;
  right: 0;
  /* height: calc(100vh - 50px); */
  height: 100vh;
  overflow: auto;
  background: #f2f2f2;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  /* -webkit-transform: translateX(110%);
    transform: translateX(110%); */
  z-index: 1000;
  /* margin-top: 10px; old */
  margin-top: 0px;
  box-shadow: -1px 14px 15px -1px rgba(0, 0, 0, 0.25);
  padding-bottom: 2%;
}

.cart-sidebar-open .container {
  width: 100%;
  max-width: 1200px;
}

.cart-sidebar-open .container table {
  width: 1070px;
}

.cart-sidebar-close {
  position: fixed;
  max-width: 1100px;
  width: 750px;
  top: 50px;
  right: 0;
  height: calc(100vh - 50px);
  overflow: auto;
  background: #fbfbfb;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  -webkit-transform: translateX(110%);
  transform: translateX(110%);
  z-index: 999999;
  margin-top: 10px;
  box-shadow: -1px 14px 15px -1px rgba(0, 0, 0, 0.25);
}

.cart {
  position: relative;
}

.cart-counter {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-weight: 900;
  text-align: center;
  top: 0px;
  left: -2px;
  padding-top: 1px;
  font-size: 14px;
}

.cartItem p {
  font-size: 12px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.mainCart {
  position: relative;
}

.sticky-cart {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: #efefef;
  padding: 0.5% 0%;
}
.sticky-cart-2 {
  position: sticky;
  z-index: 99;
  top: -22px;
  background-color: #efefef;
  padding: 1% 0%;
}

.fixed-cart {
  position: fixed;
  z-index: 100;
  top: 5em;
  right: 3%;
}

/* Custom Table */

.custom-table {
  border: 1px solid #ccc;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.custom-table tbody {
  background-color: transparent;
}

.custom-table thead tr {
  background-color: var(--light-blue);
}

.custom-table th:first-child {
  width: 60px;
}

.custom-table tr {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.35em;
  vertical-align: middle;
}

.custom-table th,
.custom-table td {
  padding: 1.5em 0.575em;
  text-align: center;
}

.custom-table th {
  font-size: 0.9em;
  text-transform: capitalize;
}

@media only screen and (max-width: 1200px) {
  .btn-font-size {
    font-size: 12px;
  }
}

@media screen and (max-width: 991px) {
  .custom-table {
    border: 0;
    max-width: 100%;
  }

  .custom-table th,
  .custom-table td {
    padding: 1em;
    border-top: none;
  }

  .custom-table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .custom-table tr {
    /* border-bottom: 3px solid #fff; */
    display: block;
    margin-bottom: 2em;
  }

  .custom-table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
    max-width: 100%;
  }

  .custom-table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    content: attr(data-label);
    float: left;
    font-weight: 500;
    text-transform: uppercase;
  }

  .custom-table td:first-child {
    display: none;
  }

  .custom-table td:last-child {
    border-bottom: 0;
  }

  .proposal-table {
    width: 790px;
  }
}

@media screen and (max-width: 768px) {
  .no-gutters > .col,
  .no-gutters > [class*='col-'] {
    padding-left: 48px;
  }

  .contentWidth {
    max-width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .custom-table td::before {
    content: ' ';
  }

  .custom-table td {
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .custom-table td::before {
    content: ' ';
  }

  .custom-table td {
    text-align: center;
  }

  .pdf-details h3 {
    font-size: 16px;
  }

  .pdf-details p {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .onoffswitch-label:before {
    width: 15px;
    height: 15px;
    top: 4px;
    margin-left: -4px;
    right: 45px;
  }

  .onoffswitch-label {
    height: 15px;
    line-height: 15px;
  }
}

/* Hariom Css */
.centerPagination {
  justify-content: center;
}

.forReplacePdf {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.typeVideoHeading {
  font-size: calc(5px + 1vw);
  font-weight: 500;
}

.selectRatioButton {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--light-blue);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ActiveSelectRatioButton {
  background: var(--light-blue);
  color: #fff;
}

.selectRatioButton:nth-child(2n) {
  border-left: none;
  border-right: none;
}

.selectRatioButton:last-child {
  border-right: 1px solid var(--light-blue);
}

/* ================================================== Toastify Css =========================================================== */
/* .Toastify__toast-body{
  
} */
.Toastify--animate-icon {
  height: 2.5rem;
  /* width: 2.5rem !important; */
  padding-left: 1rem;
  padding-right: 1.5rem;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #ededed;
}
.imageSize {
  width: 1.5rem;
  height: 1.5rem;
  flex-shrink: 0;
}

/* .Toastify__toast--success {
  background: var(--light-blue) !important;
} */
.Toastify__progress-bar {
  height: 3px !important;
}
/* ================================================== Toastify Css END ====================================================== */

/* new SidedeHeader start */
.newSideheaderMain {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.newHeaderMain {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex: 1;
  overflow-x: hidden;
}

.submenu {
  margin: 0;
}

.fontsizeNew {
  font-size: 0.875rem;
}

.fixedHeight {
  height: 3rem;
}

.fullWidthHeight {
  width: 100%;
  height: 3rem;
}

/* Text Editor Start */
.rdw-emoji-wrapper,
.rdw-fontfamily-wrapper {
  display: none !important;
}

.rdw-image-wrapper {
  display: none !important;
}

.rdw-text-align-wrapper {
  display: none !important;
}

.rdw-link-wrapper {
  display: none !important;
}

.rdw-embedded-wrapper {
  display: none !important;
}

.rdw-remove-wrapper {
  display: none !important;
}

/* .rdw-option-wrapper{
  display:none !important; ;
} */
.rdw-history-wrapper {
  display: none !important;
}

.rdw-option-active {
  display: none !important;
}

.rdw-option-wrapper:nth-child(4) {
  display: none !important;
}

.rdw-option-wrapper:nth-child(5) {
  display: none !important;
}

.rdw-option-wrapper:nth-child(6) {
  display: none !important;
}

.rdw-option-wrapper:nth-child(7) {
  display: none !important;
}

.rdw-editor-main {
  height: 100px !important;
  overflow: auto;
  box-sizing: border-box;
}

.public-DraftStyleDefault-block {
  margin: 0em !important;
}

.rdw-fontsize-wrapper {
  display: none !important;
}

.rdw-option-disabled {
  display: none !important;
}

.editorSectionStart {
  /* border: 1px solid #bec0c7; */
  min-height: 20rem;
  background-color: #fff;
}
.editorSectionStart .ql-editor {
  min-height: 20rem;
}

.DraftEditor-root {
  height: 20rem !important;
}

.codex-editor__redactor {
  padding-bottom: 0px !important;
}

.editorClassName {
  /* margin-left: 2rem;
  margin-right: 2rem; */
  overflow: auto;
}

.rdw-editor-main {
  height: 100% !important;
}

.chooseVideoLable {
  border: 1px solid;
  border-radius: 4px;
  /* background-color: gray; */
  padding: 5px 10px;
  /* color: #fff; */
}

/* Text Editor End */

/* new SidedeHeader start */

.regionSelect {
  min-width: 13rem;
}

.closeIconSidebar {
  border-right: 1px solid #cfcfcf;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.newProfileInfo {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #f28a8a;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.newProfileInfo p {
  color: #fff;
  font-family: 'Inter', sans-serif !important;
  font-size: 0.8rem;
  margin: 0;
}

.profile-dropdown .newProfileInfo:hover,
.profile-dropdown .newProfileInfo:active,
.profile-dropdown .newProfileInfo:focus {
  background-color: #f28a8a !important;
}

.openSidebarTransform {
  transform: rotate(180deg);
}

/* .newProfileInfo:hover, .newProfileInfo:active, .newProfileInfo:focus{
  background-color: #F28A8A !important;
} */

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  width: 40px !important;
}

.public-DraftStyleDefault-block {
  margin-left: 0.5rem !important;
}

.yaxisText {
  font-size: 6px !important;
}

.emailTableWidth {
  max-width: 100% !important;
}

.starImg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.productTableFont td {
  font-size: 0.8rem;
}

/* Collections page start */
.collectionsListMain {
  margin-top: 3rem;
  font-family: 'Inter', sans-serif !important;
}

.collectionsListCard {
  width: 100%;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 4px;
  /* box-shadow: 0px 6px 5px -2px rgb(0 0 0 / 20%) !important; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.w-49 {
  width: 49%;
}

.widthSearchCollection {
  width: 30%;
}

.collectionsContent h4 {
  font-weight: 500;
  font-size: 1.2rem;
}

/* .collectionsContent p{
  font-size: 12px;
} */
.priceAndbnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceAndbnt h5 {
  color: #753cb7;
  font-weight: 500;
}

.collectionProductContent h3 {
  font-size: 1.2rem;
}

.collectionProductContent h5 {
  font-size: 1rem;
}

.collectionProductContent h6 {
  color: #753cb7;
  font-size: 1rem;
}

.collectionProductContent p {
  font-size: 0.7rem;
}

.CollectionsProductListMain {
  width: 100%;
  overflow: hidden;
}

/* .productImg{
  height: 12rem;
} */

.fontInter {
  font-family: 'Inter', sans-serif !important;
}

.editDeleteModal {
  background-color: #ffffff;
  padding: 1rem;
  padding-right: 2rem;
  border-radius: 4px;
  box-shadow: 0px 6px 5px -2px rgb(0 0 0 / 20%) !important;
  position: absolute;
  right: 0;
}

.editIconMain {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.2rem;
  cursor: pointer;
}

.editIconMain p {
  margin-bottom: 0;
  margin-left: 1rem;
}

.collectionModelHeader h3 {
  font-size: 1.2rem;
}

.collectionModelHeader p {
  font-size: 0.8rem;
  font-weight: 400;
}

.backgroundTheme .modal-content {
  background-color: var(--background);
}

.modalOverflowAuto {
  max-height: 80vh;
  overflow: auto;
}

/* .collectionInputWidth{
  width: 25%;
  margin-left: 1rem;
  margin-right: 1rem;
} */
/* .widthSearchCollectionCreate{
  width: 20%;
} */

/* @media screen and (max-width : 768px) {
  .collectionInputWidth, .widthSearchCollectionCreate{
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
} */

.addRemoveBtnProductCollection {
  border: 1px solid #753cb7;
  border-radius: 4px;
  display: flex;
  justify-content: space-around;
  width: 44%;
}

.btnWidthAdd {
  width: 44%;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.addRemoveBtnProductCollection button {
  padding: 0rem 0rem;
}

.addRemoveBtnProductCollection span {
  padding: 0rem 0.5rem;
  justify-content: center;
  align-items: center;
}

.borderQuantityProduct {
  border-left: 1px solid #753cb7;
  border-right: 1px solid #753cb7;
  /* padding-left: 1rem;
  padding-right: 1rem; */
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .gridFiveColumn {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media (min-width: 1400px) and (max-width: 1600px) {
  .gridFiveColumn {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.quantityFontSize {
  font-size: 0.85rem !important;
}

/* Collections page end */

/* create proposal scroll  */
/* [data-react-beautiful-dnd-droppable="0"]{
  max-height: 70vh;
  overflow: auto;
}
[data-react-beautiful-dnd-droppable="0"]::-webkit-scrollbar {
  width: 0px;
}
[data-react-beautiful-dnd-droppable="0"]::-webkit-scrollbar-track {
  background-color: none;
}
[data-react-beautiful-dnd-droppable="0"]::-webkit-scrollbar-thumb {
  box-shadow: none;
} */
/* .dragAndDropMainDiv {
  max-height: 70vh;
  overflow: auto;
}

.dragAndDropMainDiv::-webkit-scrollbar {
  width: 0px;
}

.dragAndDropMainDiv::-webkit-scrollbar-track {
  background-color: none;
}

.dragAndDropMainDiv::-webkit-scrollbar-thumb {
  box-shadow: none;
} */

/* create proposal scroll  */

.allPageHeaderText h3 {
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  font-family: 'Inter', sans-serif !important;
}

/* New Table Design  start*/
.newTableDesignAll {
  text-align: center;
  font-family: 'Inter', sans-serif !important;
  border: 1px solid #dadce0;
  background-color: #ffffff;
}

.newTableDesignAll thead th {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  vertical-align: middle;
  color: #1e293b;
  border-bottom: 1px solid #dadce0;
}

.newTableDesignAll tbody tr td {
  border-bottom: 1px solid #dadce0;
  vertical-align: middle;
  color: #1e293b;
  font-size: 14px;
  font-weight: 400;
}

.userStatusActive {
  color: #519b4a !important;
}

.userStatusClose {
  color: #ed5e5e !important;
}

.transferProposals {
  background: #fdfdfd;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  width: 70%;
  cursor: pointer;
  text-align: center;
}

.transferProposals p {
  margin: 0;
  padding: 0.4rem;
}

.positionRelative {
  position: relative;
}

.onoffswitchUserTable {
  position: relative;
  width: 30px;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox-userTable {
  display: none;
}

.onoffswitch-label-userTable {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 13px;
  padding: 0;
  line-height: 20px;
  border: 0px solid #ffffff;
  border-radius: 30px;
  background-color: #9e9e9e;
  margin-bottom: 0;
}

.onoffswitch-label-userTable:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  margin: -5px;
  background: #ffffff;
  position: absolute;
  top: 6.3px;
  bottom: 0;
  right: 23px;
  border-radius: 30px;
  box-shadow: 0 6px 12px 0px #757575;
}

.onoffswitch-checkbox-userTable:checked + .onoffswitch-label-userTable {
  background-color: var(--dark-purple);
}

.onoffswitch-checkbox-userTable:checked + .onoffswitch-label-userTable,
.onoffswitch-checkbox-userTable:checked + .onoffswitch-label-userTable:before {
  border-color: var(--dark-purple);
}

.onoffswitch-checkbox-userTable:checked + .onoffswitch-label-userTable .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox-userTable:checked + .onoffswitch-label-userTable:before {
  right: 7px;
  background-color: #ffffff;
  box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
}

.containerForUser {
  font-family: 'Inter', sans-serif !important;
}

@media (min-width: 1500px) {
  .containerForUser {
    max-width: 94%;
    margin: auto;
  }
  .Sticky-Pagination {
    padding-left: 2rem;
  }
}

button.close:focus {
  outline: none !important;
}

.close {
  font-size: 1.8rem;
}

.NoItemInCartMain {
  width: 100%;
  height: calc(100vh - 1rem);
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoItemInCartMain h4 {
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
}

.page-item.active .page-link:focus,
.page-link:focus {
  outline: none !important;
  box-shadow: none !important;
}

/* New Table Design end */

.updatePriceOrNotBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.goBackAllPage {
  margin: 1.5rem;
}

.goBackAllPage a {
  font-size: 1rem;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500;
  border-bottom: 1px solid #000;
  cursor: pointer;
}

.proposalLogoMain {
  display: flex;
}

.proposalLogoMain label {
  min-width: 100px;
  margin-bottom: 0;
}

.WidthAndHeightLogoText {
  font-size: 0.8rem;
}

.userDetailsEdit {
  margin-bottom: 1rem;
}

.viewProposalCloneModalMsg h5 {
  font-family: 'Inter', sans-serif !important;
  font-size: 1rem;
  font-weight: 500;
}

.viewProposalCloneModalMsg p {
  font-size: 0.9rem;
  font-family: 'Inter', sans-serif !important;
}

.backdropCart {
  z-index: 999 !important;
}

.cart-sidebar-open {
  z-index: 1030;
}

.container,
.container-fluid {
  max-width: 100% !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

table {
  text-align: left !important;
  font-family: 'Inter', sans-serif !important;
  border: 1px solid #dadce0 !important;
  background-color: #ffffff !important;
  border-radius: 4px;
}

table thead th {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  vertical-align: middle;
  color: #1e293b;
  border-bottom-width: 0 !important;
  border-bottom: 1px solid #dadce0;
  background-color: #e6e6e6 !important;
  padding: 1.5em 0.575em !important;
  text-align: left !important;
}

table tbody tr td {
  border-bottom: 1px solid #dadce0;
  vertical-align: middle !important;
  color: #1e293b;
  font-size: 14px;
  font-weight: 400;
  text-align: left !important;
  padding: 0.75rem 0.575em !important;
}

table tbody tr {
  text-align: left !important;
}

.formGroupAddUser label {
  letter-spacing: 0.001em;
  color: #1e293b;
  font-size: 0.875rem;
  font-weight: 500;
}

.formGroupAddUser input,
.formGroupAddUser input:focus {
  border: 1px solid #f3f3f3;
  font-size: 0.875rem;
}

.formGroupAddUser input::placeholder {
  letter-spacing: 0.001em;
  color: #929292;
  font-size: 0.875rem;
}

.formGroupAddUser .SelectReact {
  color: #929292;
  font-size: 0.875rem;
}

.formGroupAddUser .SelectReact:focus-visible {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.formGroupAddUser .SelectReact div {
  box-shadow: none !important;
}

.formGroupAllPage label {
  letter-spacing: 0.001em;
  color: #1e293b;
  font-size: 0.875rem;
  font-weight: 500;
}

.formGroupAllPage input,
.formGroupAllPage input:focus {
  border: 1px solid #f3f3f3;
  font-size: 0.875rem;
}

.formGroupAllPage input::placeholder {
  letter-spacing: 0.001em;
  color: #929292;
  font-size: 0.875rem;
}

.formGroupAllPage .SelectReact {
  color: #929292;
  font-size: 0.875rem;
}

.formGroupAllPage .SelectReact:focus-visible {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.formGroupAllPage .SelectReact div {
  box-shadow: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.marginTopAllHeader {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.allFillBlueBtn {
  background: var(--btn-blue);
  border-radius: 4px;
}

.btn.btn-light-purple,
.btn.btn-light-purple:focus,
.btn.btn-light-purple:active {
  background-color: var(--btn-dark-purple) !important;
  transition: all 0.15s ease-in-out;
  border-radius: 4px;
  border: none !important;
  outline: none !important;
}

.btn.btn-light-purple:hover {
  background-color: var(--btn-light-purple) !important;
}

.fullHeightLogin {
  min-height: 100vh;
}

.otpInputStyle {
  border: 1px solid #1e1e1e !important;
  font-size: 0.875rem !important;
  margin: 0 0.5rem;
  min-width: 40px;
  min-height: 40px;
  border-radius: 3px;
}

.otpInputStyle:first-child {
  margin-left: 0 !important;
}

.nobgBtn {
  border: 1px solid #753cb7 !important;
  font-weight: 500;
  text-align: center;
  color: #753cb7;
  background-color: #fff !important;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  line-height: 19px;
  outline: none !important;
}

.nobgBtn:hover,
.nobgBtn:active,
.nobgBtn:focus {
  background-color: #fff !important;
  color: #753cb7 !important;
}

.fontsize14 {
  font-size: 14px;
  margin-left: 0.5rem;
  line-height: 21px;
}

/* new search */
.newSearchbarMain {
  width: 100%;
  /* margin-bottom: 2rem; */
}

.newSearchIcon {
  display: flex;
  align-items: center;
  padding: 0.2rem 1rem;
  background: #ffffff;
  border-radius: 2px;
}

.SearchInput {
  border: none !important;
  color: #1e293b;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  padding: 0.5rem;
}

.SearchInput::placeholder {
  color: #d1d1d1;
  font-weight: 300;
}

.SearchInput:focus,
.SearchInput:active {
  border: none !important;
  color: #1e293b;
}

/* new search */

/* copy pdf start */

.CopyPdfUrlMain {
  display: flex;
  align-items: center;
  width: 100%;
}

.CopyPdfUrlMain input {
  border-right: 0;
  max-width: calc(100% - 150px);
  height: 40px;
  outline: 0;
  line-height: 1;
  padding-left: 15px;
  padding-right: 15px;
  border: 2px solid #000 !important;
  border-color: #000;
  width: 100%;
}

.CopyBtnPdfLink {
  width: 150px;
  height: 40px;
  padding: 0 20px;
  background: #000;
  outline: 0;
  box-shadow: none;
  border: 0;
  color: #fff;
  line-height: 35px;
  vertical-align: top;
  cursor: pointer;
}

/* copy pdf end */
.TabNavMain {
  border: none !important;
  margin-bottom: 2rem;
}
.AllPagesTabStyles,
.AllPagesTabStyles:hover {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #929292 !important;
  border: none !important;
  cursor: pointer;
  padding: 0 !important;
  padding-bottom: 0.7rem !important;
  margin: 0.5rem 1rem !important;
}

.ActiveTabAllPage,
.ActiveTabAllPage:hover {
  color: #753cb7 !important;
  border-bottom: 3px solid #753cb7 !important;
}
.marginLeft0,
.marginLeft0:hover {
  margin-left: 0 !important;
}
.reduceHeightforCollateral {
  max-height: 170px;
}
/* Hariom Css */

/* New CSS for Form data  */

plant-table tbody {
  background: #ffffff;
  border: 0.5px solid #f0f1f1;
}

.plant-table tbody tr {
  background: #ffffff;
  border: 0.5px solid #f0f1f1;
}

.plant-table .tfooter div {
  padding: 20px;
}

.plant-table thead tr th {
  padding: 20px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #1e1e1e;
}

.plant-table table {
  width: 100%;
}

.plant-table thead tr {
  border: 1px solid #f0f1f1;
  background: #f9fbfc;

  border-radius: 4px 4px 0px 0px;
}

.plant-table thead tr td {
  text-align: center;
  display: flex;
}

.plant-table tbody tr td {
  text-align: center;
  padding: 6px;
}

.plant-table tbody tr td input {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 12px;
  max-width: 150px;
}

.plant-table tbody tr td select {
  background: #ffffff;
  border: 1px solid #e6e6e6;
  padding: 12px;
  border-radius: 4px;
}

.plant-table .tfooter {
  border: 1px solid #f0f1f1;
  background: #f9fbfc;

  border-radius: 4px 4px 0px 0px;
}

/* ======================= CSS Changes by Anubhav =========================== */

.collectionProductContent {
  padding-top: 1rem;
}
.information-title {
  color: var(--1, #1e293b);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.00088rem;
}
.page-item .selected {
  background-color: #007bff;
  color: white;
}

.modal {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.center-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Create-model input {
  width: 97%;
  padding: 11px 0px 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}
.inputDrops {
  /* width: 39.5rem; */
  border: 2px dashed var(--unnamed-color-292728);
  flex-direction: column;
  border-radius: 8px;
  height: 100%;
  width: 96%;
  padding: 3rem 5.6rem;
  min-height: 200px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: var(--unnamed-color-FCF8EF);
  cursor: pointer;
  max-width: 100% !important;
  border-radius: 4px;
  border: 1px dashed var(--text-box-stroke, #929292);
}

/* Colletaral Setting DropZone */

.collateralsetting .Create-model input {
  width: 97%;
  padding: 11px 0px 10px 16px;
  border-radius: 4px;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}
.dropzoneMargin {
  width: 95% !important;
  /* margin-right: 25px; */
  /* margin-left: 13px; */
}
.collateralsetting .boarder {
  /* width: 39.5rem; */
  margin-right: 25px;
  margin-left: 13px;
  width: 100%;
  height: 7.6875rem;
  /* border: 2px dashed var(--unnamed-color-292728); */
  flex-direction: column;
  border-radius: 8px;
  background: var(--unnamed-color-FCF8EF);
  cursor: pointer;
  text-align: center;
  padding: 1rem 0.3rem;
  /* border: 1px dashed var(--text-box-stroke, #929292); */
}
.dropzone-border {
  border: 2px dashed var(--unnamed-color-292728);
  background: var(--unnamed-color-FCF8EF);
  border: 1px dashed var(--text-box-stroke, #929292);
  cursor: pointer;
  padding: 1rem 0rem;
  margin: 0;
  border-radius: 8px;
}
.PM-setting .boarder {
  /* width: 39.5rem; */
  margin-right: 25px;
  margin-left: 13px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  text-align: center;
}
.collateralsetting .custom-drop {
  display: flex;
  width: auto;
  height: 7.6875rem;
  padding: 1.5rem 2.96875rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px dashed var(--text-box-Stroke, #929292);
}
.PM-setting .collateralsetting .DropzoneText {
  color: var(--text-box-Stroke, #929292);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.button-cetting {
  height: 2rem !important;
  margin-top: 1.5rem;
}
.dropzone-font-size,
.dropzone-font-size span {
  font-size: 0.9vw;
}

/* End */

.custom-drop {
  text-align: center;
  min-height: 170px;
  display: flex;
  padding: 1.5rem 5.5rem !important;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

.Add-button-A {
  /* color: #753CB7; */
  border-radius: 4px;
  background: #753cb7;
}
.cancel-button-A {
  background-color: white;
  border-radius: 4px;
  color: #753cb7;
}

.previewImage {
  width: auto;
  height: 6.25rem;
  flex-shrink: 0;
  padding: 0.5rem;
  border-radius: 4px;
  border: 1px dashed var(--text-box-stroke, #929292);
}
.previewImage img {
  width: 100%;
  height: 100%;
  /* margin: 0.5rem; */
}

.forCollectionFixWidth {
  width: 1000px !important;
}

.nobackgroundimage {
  background-image: none !important;
}

@media screen and (max-width: 1000px) {
  .inputDrops {
    height: 100%;
    padding: 3rem 0.6rem;
  }
}

.color-underline {
  /* text-decoration: underline; */
  /* color: var(--1, #753CB7);
  padding-bottom: 10px;
  line-height: normal;
  border-bottom: 1.9px solid var(--1, #753CB7);
  display: inline-block;
  padding-bottom: 2px; */

  color: var(--1, #753cb7);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.red-color {
  color: #ed5e5e;
}

.collectionImg {
  min-height: 8rem;
}

/**
        Add User CSS
*/

/* Custom button Classes */
.small-button {
  display: flex;
  height: 2rem;
  padding: 0.6875rem 1.99994rem 0.6875rem 2.00006rem;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  flex-shrink: 0;
  border: none;
  border-radius: 0.25rem;
}
.medium-button {
  width: 100%;
  display: flex;
  font-size: 14px;
  height: 2.375rem;
  font-weight: 600;
  padding: 0rem 2rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: 0.25rem;
}
.large-button {
  font-size: 14px;
  display: flex;
  height: 38px;
  font-weight: 600;
  font-size: 14px;
  padding: 0rem 2rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border: none;
  border-radius: 0.25rem;
}

.Primary-Button {
  background: #753cb7;
  color: white;
  /* font-weight: 500; */
}
.Primary-Button:active {
  background: #753cb7;
  color: white;
}
.Primary-Button:hover {
  background: #9a6ecc;
  font-weight: 500;
}
.Secondary-Button {
  font-weight: 500;
  background: transparent;
  border-radius: 0.25rem;
  border: 1px solid #753cb7 !important;
  color: #753cb7;
}
.Primary-Button:disabled {
  border-radius: 0.25rem;
  border: 1px solid #753cb7;
  opacity: 0.5;
  background: #753cb7;
}

.Forth-color {
  font-weight: 500;
  border-radius: 0.5rem;
  border: 1px solid var(--border, #e6e6e6);
  background: #fdfdfd;
  color: #3a3a3a;
}
.Forth-color:hover {
  background: #e6e6e6;
}
.Secondary-Button:hover {
  border: 1px solid #753cb7;
  background: #e6e6e6;
}
.Tertiary-Button {
  font-weight: 500;
  background: transparent;
  border-radius: 0.25rem;
  color: #753cb7;
}
.Tertiary-Button:hover {
  background: #e6e6e6;
}

/* Custom Input Classes */

/* Default styles for .Custom-input */

.sm-input {
  height: 2.375rem !important;
}
.Custom-input:read-only {
  border-radius: 0.25rem;
  border: 1px solid var(--border, #e6e6e6);
  background-color: #e9ecef;
  opacity: 1;
}

.Custom-input {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  display: inline-flex;
  padding: 0.75rem 0.75rem 0.5625rem 0.5rem;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  border: 1px solid var(--border, #e6e6e6);
}

.Custom-input:hover,
.css-13cymwt-control:hover {
  border: 1px solid #bbbbbb !important;
  border-radius: 4px;
  border-color: #bbbbbb !important;
}
.css-13cymwt-control:hover {
  border-color: #bbbbbb !important;
}
.Custom-input:focus {
  border: 1px solid #753cb7 !important;
  border-radius: 4px;
}
.custom-label {
  color: var(--1, #1e293b);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.00088rem;
}
.lg-checkmark {
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  border-radius: 0.25rem !important;
  border: 1px solid #e6e6e6 !important;
}

.md-input {
  display: inline-flex;
  height: 2.375rem;
  padding: 0.625rem 0.625rem 0.6875rem 1rem;
  align-items: center;
  flex-shrink: 0;
}
.md-checkmark {
  width: 1.2rem;
  height: 1.2rem;
  background-color: #fff;
  border-radius: 0.25rem !important;
  border: 1px solid #e6e6e6 !important;
}
.sm-checkmark {
  width: 1rem;
  height: 1rem;
  background-color: #fff;
  border-radius: 0.25rem !important;
  border: 1px solid #e6e6e6 !important;
}
.checkmark:checked {
  /* background-color: black; */
  border-radius: 0.25rem !important;
  border: 1px solid #e6e6e6 !important;
}
input[type='checkbox'] {
  cursor: pointer;
}
input[type='checkbox']:checked {
  accent-color: #753cb7;
  color: #ffffff !important;
  cursor: pointer;
}
input[type='checkbox']:after {
  box-shadow: none !important;
}
/* Media query for screens with a maximum width of 768px */
@media (max-width: 768px) {
  .Custom-input {
    padding: 0.75rem 0.5rem; /* Adjust padding for smaller screens */
  }
}

/* Media query for screens with a maximum width of 576px */
@media (max-width: 576px) {
  .Custom-input {
    padding: 0.5rem; /* Further adjust padding for even smaller screens */
  }
}

/* Custom Form  */

.Title-small {
  color: var(--text, #929292);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.00088rem;
}

.Title-medium {
  color: var(--1, #1e293b);
  font-family: Inter;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
/* .Title-large {
} */

/* Custom Tabbar */

.tab-unSelect {
  color: var(--text, #929292) !important;
}
.tab-Select {
  color: var(--1, #753cb7) !important;
}
.tab-hr {
  border: 2px solid;
  border-radius: 5px;
  margin-top: 13px !important;
}

/* Proposal Setting  */

.main-title {
  /* Common for all heading*/
  color: var(--1, #1e293b);
  font-family: Inter;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.Form-button {
  margin-top: 8.4rem !important;
}

button:focus,
textarea:focus {
  outline: none;
}

.Add-edit-Height {
  height: 37.25rem;
}

/* Card Size  */

.card-common {
  border-radius: 0.5rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}
.fixed-height {
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
  height: 28rem !important;
}
.Card-small {
  background: white;
  width: 100%;
}

.Size-adjust {
  min-width: 500px !important;
}

/* Custom Table */

.Custom-Table {
  width: 100%;
}
.Custom-Table {
  text-align: center !important;
}

.arrow-classs {
  cursor: pointer;
  display: flex;
  align-items: center;
}

/* Add User */

.form-flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Adjust the gap as needed */
}

.form-flex-item {
  flex: 0 0 calc(33.33% - 16px); /* Adjust the width and gap as needed */
}

.user-permissions .form-body {
  padding: 0 !important;
}

/* Mange User  */

/* .table-center-new td, */
/* .table-center-new th {
  text-align: center !important;
} */
.img-placeholder {
  width: 3.5rem;
  height: 3.5rem;
  flex-shrink: 0;
}

/* Checkbox  */

/* The switch - the box around the slider */
.switch {
  padding: 0;
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 1rem;
  margin: 0;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  /* Increase the height and width of the slider */
  height: 25px;
  width: 43px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #9c9c9c;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  /* Increase the height and width of the thumb */
  height: 20px; /* Adjust to your desired height */
  width: 20px; /* Adjust to your desired width */
  position: absolute;
  content: '';
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3f0a87;
}
/* border-color: var(--dark-purple); */
input:focus + .slider {
  box-shadow: 0 0 1px #3f0a87;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* End checkbox */

.product-sku .Innertable {
  /* text-align: center !important; */
  vertical-align: center !important;
}

/* .Table-footer {
} */
.Scroll-body {
  max-height: 100px;
  overflow-x: scroll;
}
.Scroll-table {
  overflow-y: auto;
  max-height: 600px;
  width: 100% !important;
}
.Table-footer {
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: 1px solid #dadce0;
  background: #fff;
}
.Custom-Table {
  margin: 0;
  padding: 0;
}
.pagination-btn {
  /* width: 13px; */
  cursor: pointer;
  background: transparent;
  border: 0;
}
.rowssize {
  /* height: 56px; */
  background: #f9fbfc;
  border: 1px solid #e6e6e6;
  padding: 13px 54px;
  border-radius: 0px 0px 4px 4px;
}
.companytext {
  font-size: 14px;
}

.product-sku thead th {
  background-color: white !important;
}
.product-sku thead {
  border-radius: 0.25rem 0.25rem 0rem 0rem !important;
  border: 1px solid #dadce0 !important;
  background: #fff !important;
}

/* Search Bar Classes */

.search-box {
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
  width: auto;
  height: 2.375rem;
  margin-right: 2rem;
}

/* .search-box i {
  position: absolute;
  left: 12px;
  top: 12px;
} */
.width-80 {
  width: 35%;
}
input[type='radio']:checked {
  accent-color: #753cb7 !important;
  background-color: #753cb7 !important;
  border-color: #753cb7 !important;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}
/* controls the all type search input fields */
input[type='search'] {
  display: inline-block;
  /* background-color: lightgrey; */
  width: 100%;
  /* font-size: 1.5rem; */
  padding-left: 0.5rem;
  /* padding-right: 5rem; */
  padding-bottom: 0.2rem;
  color: gray;
  outline: none;
  border: none;
  border-radius: 10px;
}

/* controls the search cancel button */
[type='search']::placeholder {
  /* -webkit-appearance: none;
  appearance: none; */
  color: #d1d1d1;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  /* background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
  background-size: 10px 10px; */
}

/* End */

/* Scroll bar CSS */

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 0.3125rem;
  background: #cacaca;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  border-radius: 0.3125rem;
  background: #cacaca;
}

.custom-dropdown {
  position: relative;
  width: 200px; /* Adjust as needed */
}

.dropdown-header {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
}

.dropdown-list li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-list li:hover {
  background-color: #f0f0f0;
}
.dropdown-color {
  color: #000;
  border-radius: 0.25rem !important;
  border: 1px solid #dadce0 !important;
  background-color: transparent !important;
}
.dropdown-color :hover {
  color: #000;
  background-color: transparent !important;
}
/* .dropzon-innerpreview {
  border: 2px solid;
} */
.DropzoneText p {
  color: var(--text-box-Stroke, #929292);
  font-family: Inter;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* Sticky Head CSS */

.Stickyhead-class {
  position: absolute;
  top: 0;
  left: 0;
  display: flex !important;
  justify-content: space-between !important;
  background: #fff !important;
  box-shadow: 0px 5px 20px 0px #dfdfdf !important;
  width: 100%;
}

/* End  */

/* Analayatic CSS */

.Select-input {
  /* height: 2.375rem; */
  margin-right: 2rem;
  width: 22rem;
  gap: 10.625rem;
  flex-shrink: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: 0.001em;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  background: #ffffff;
  /* text box Stroke */
  border-style: none;
  /* color: #929292; */
}
.Select-input-crm {
  /* height: 2.375rem; */
  margin-right: 2rem;
  width: 100% !important;
  gap: 10.625rem;
  flex-shrink: 0;
}
.dropdown-toggle::after {
  margin-left: 5.69em;
}
.dropdown-toggle {
  border-radius: 0.25rem;
  background: transparent;
  color: var(--1, #1e293b);
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00088rem;
  border: 1px solid var(--stroke, #e6e6e6);
}
.threedot-drop button {
  border-radius: 0.25rem !important;
  border: 1px solid var(--stroke, #e6e6e6) !important;
  background: #fff !important;
}
.dateinput {
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}
.buttonclass {
  color: #3a3a3a !important;
  text-align: center;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Transparent {
  color: #000;
  border-radius: 0.25rem !important;
  background: #fff;
}
.black {
  color: white;
  border-radius: 0.25rem !important;
  background: var(--1, #1e293b);
}
.active-button {
  border-radius: 0.5rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}
.Transparent:hover {
  color: #000;
  border-radius: 0.25rem !important;
  background: #fff;
}
.black:hover {
  color: white;
  border-radius: 0.25rem !important;
  background: var(--1, #1e293b);
}
.active-btn {
  padding: 0;
  height: 100%;
  width: auto;
  padding: 0.2rem 0.5rem;
  justify-content: center;
  align-items: center;
  display: flex;
}
.graph-pagination {
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}
/* End */

.me-2 {
  margin-right: 0.5rem !important;
}
.style-button {
  padding: 20px !important;
}
.collateralcard p {
  color: var(--text-box-Stroke, #929292);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
a {
  text-decoration: none;
}

/* Collateral Setting */

.image-box {
  width: 100%;
  height: 22.9375rem;
  flex-shrink: 0;
}

.input-height-sm input {
  height: 2.375rem;
  width: 100%;
}
.form-flex-item-setting {
  flex: 0 0 calc(50% - 16px); /* Adjust the width and gap as needed */
}

.target-height {
  height: 22.6rem;
}

.color-class .custom-label {
  color: #929292;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.tree-node {
  display: flex;
  align-items: center;
  margin-bottom: 10px; /* Adjust margin as needed */
}

.tree-node input[type='checkbox'] {
  margin-right: 5px;
}

.dotted-line {
  border-left: 1px dashed #000;
  padding-left: 10px;
  margin-left: 10px; /* Adjust margin as needed */
}
.form-flex-Collateral-setting {
  flex: 0 0 calc(33.33% - 16px); /* Adjust the width and gap as needed */
}
.padding-class {
  width: 7.5rem;
  height: 2.375rem;
}

.Radio-border-active {
  border-radius: 0.25rem;
  border: 2px solid #753cb7;
  background: #fff;
}
.radio-box-setting {
  cursor: pointer;
  display: flex;
  width: 100%;
  height: 5rem;
  padding: 1.75rem 2rem 1.75rem 1.5rem;
  align-items: center;
  gap: 1.3125rem;
  flex-shrink: 0;
}
.Radio-border-inactive {
  border-radius: 0.25rem;
  border: 1px solid var(--stroke, #e6e6e6);
  background: #fff;
}

.custom-height {
  height: 22.187rem;
}
/* End */

/* Date Picker CSS */

.chrome-picker {
  width: 100% !important;
}

/* .photoshop-picker:first-child div{
  display: none;
} */
.pwa-media {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.selet-ui-pwa .Select-input {
  display: inline-flex;
  height: 2.375rem;
  padding: 0.4375rem 0rem 0.4375rem 0rem;
  width: 100%;
  align-items: center;
  gap: 20.6875rem;
  flex-shrink: 0;
}
.css-13cymwt-control,
.css-13cymwt-control,
.css-t3ipsp-control,
.css-1xc3v61-indicatorContainer {
  width: 100%;
  /* border: 1px solid var(--border, #e6e6e6) !important; */
  /* border-color: #e6e6e6; */
}
/* .selet-ui-pwa .Select-input div{
  width: 100%;
} */

/* End */

a,
Link {
  text-decoration: none !important;
}
/* .btn-secondary:hover {
  color: #000 !important;
} */
.chart-container {
  width: 100%;
  height: 300px; /* Set the desired height */
  transition:
    width 0.3s ease,
    height 0.3s ease !important;
}
.chart-container-scroll {
  width: 1896px;
  height: 300px; /* Set the desired height */
  transition:
    width 0.3s ease,
    height 0.3s ease !important;
}

/* Scroll Graph  */

.chartWrapper {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
}

.chartWrapper > canvas {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.chartAreaWrapper canvas {
  width: calc(100% + 200px) !important;
}
.chartAreaWrapper {
  width: calc(100% - 28px);
}

/* End */

/* Chart Tooltip  */

.chartjs-tooltip {
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
}

/* End */

/* Produxt master Setting */

.setting-export {
  border-radius: 0.25rem;
  border: 1px solid #ddd;
  background: #fff;
}
.PM-setting-region input {
  border-radius: 0.25rem;
  /* border: 0.5px solid var(--text-box-Stroke, #929292) !important; */
  background: #fff;
}
.PM-setting-region input::placeholder {
  color: var(--text-box-Stroke, #929292) !important;
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.00088rem;
}
.bt-4 {
  margin-top: 0.4rem !important;
}
.fixed-height .form-body {
  height: 285px;
  max-height: 300px;
}
.dropdown-PM .dropdown-color {
  border: none !important;
  padding: 0;
}
.gap-dropdownarrow {
  gap: 0.5rem;
}
/* End */

/* Dashboard  */

.Dashboard-image {
  position: relative;
  width: 100%;
  height: 9rem;
  background-image: url('../bg.png');
  /* background-repeat: round; */
  object-fit: cover;
  background: #aef0f3;
}
.Dashboard-image-gap {
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Dashboard-image-heading {
  color: #1e293b;
  font-family: Inter;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Dashboard-image-qoute {
  color: #1e293b;
  font-family: Inter;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
/* End */

/* Profile CSS */

.padding-css .form-body {
  padding: 0 !important;
}

/* End */

/* Ham Burger */

.hamburgerIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.25rem;
  background: #dadada;
  z-index: 1000;
}
/* End */

/* Sticky Pagination */

.Sticky-Pagination {
  padding-left: 0rem;
  gap: 3rem;
  border-radius: 0rem 0rem 0.25rem 0.25rem;
  border: 1px solid #dadce0;
  background: #fff;
  position: fixed;
  bottom: 0;
  display: flex;
  text-align: center;
  /* left: calc(100%-100vh); */
  width: -webkit-fill-available;
  left: 15rem;
  z-index: 1;
  /* justify-content: space-around; */
  /* max-width: calc(100%-100vh); */
}
.Sticky-Pagination .page-item {
  margin-left: 1rem;
}
.Sticky-Pagination .pagination {
  margin: 0;
  padding: 11px;
}
.Sticky-Pagination .page-item.active .page-link:focus,
.Sticky-Pagination .page-link:focus {
  color: black;
  border-radius: 0.25rem;
  background: transparent;
  outline: none !important;
  box-shadow: none !important;
}

.Sticky-Pagination .page-item.active .page-link-2:focus,
.Sticky-Pagination .page-link-2:focus {
  color: black !important;
  border-radius: 0.25rem;
  /* background: #753cb7; */
  background: transparent !important;
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}
.Sticky-Pagination .page-link {
  border-radius: 0.25rem;
  border: 1px solid #dadce0;
  background: #fff;
}
.page-link-2 {
  border: none !important;
}
.Sticky-Pagination .page-item .selected {
  background: transparent;
  color: white;
}
.Sticky-Pagination .page-item.active .page-link {
  z-index: 1;
  color: #fff !important;
  background: var(--1, #753cb7) !important;
  border-color: white !important;
}
.Sticky-Pagination .page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: black;
  background-color: #fff;
}
/* End */

/* Cart Page */

.Summarymaindiv .form-body {
  padding: 0 !important;
}
.cart-sidebar-open-v2 {
  max-width: 1593px !important;
}

.discound-type .dropdown .Select-input {
  width: 18rem;
}
.discound-type .percent .Custom-input {
  width: 5rem;
}
.display-statuss {
  gap: 0.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* flex-direction: column; */
}
.margin-top {
  margin-top: 375px !important;
}

.form-control {
  padding: 0.5rem 0.75rem !important;
  /* height: calc(2.25rem + 6px) !important; */
}
.changewidth {
  padding: revert !important;
}
/* End */

/* Collapse div */

/* .Collaps-height .collapse{
  height: 20rem !important;
} */

/* End */

/* Media Query */

@media screen and (max-width: 824px) {
  .large-button {
    width: 9rem !important;
    font-size: 13px;
  }
}
@media screen and (max-width: 768px) {
  .Sticky-Pagination {
    padding-left: 0rem;
    left: 0 !important;
  }
}

/* Checkbox dropdown */

.checkbox-dropdown {
  position: relative;
  /* display: inline-block; */
}

.checkbox-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
  margin-top: 5px;
}

.checkbox-item {
  padding: 8px;
}

.checkbox-item label {
  margin-left: 8px;
}

/* Style to hide native checkbox */
.checkbox-item input[type='checkbox'] {
  position: absolute;
  opacity: 0;
}

/* Style for custom checkbox appearance */
.checkbox-item input[type='checkbox'] + label:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px solid #aaa;
  background-color: #fff;
  margin-right: 5px;
  vertical-align: middle;
}

.heightfix {
  height: 20rem;
}

/* Style for the container label */
.checkbox-dropdown label {
  display: block;
  margin-bottom: 5px;
}

/* // Colors for status */
.statusclass {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
}
.statusbox {
  width: 0.25rem;
  height: 1rem;
}

.Pending {
  color: #edbc5c;
  background-color: #edbc5c;
}
.NotConverted {
  color: #ed5e5e;
  background: #ed5e5e;
}
.Converted {
  color: #79ed5c;
  background: #79ed5c;
}

.informationdiv {
  width: 100%;
  height: auto;
  border-radius: 0.25rem;
  background: #ded0ef;
  display: flex;
  height: 3.625rem;
  padding: 1rem 0rem 1rem 2rem;
  align-items: center;
  flex-shrink: 0;
}
.informationdiv span {
  color: var(--1, #1e293b);
  font-family: Inter;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.valuediv {
  width: 11%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.w-80 {
  width: 50%;
}
input[type='checkbox']:checked {
  /* background: #753CB7; / Change this to a color for testing / */
  background: url('../V2-images/check.svg') !important;
  border-color: #753cb7;
}

.css-qbdosj-Input input {
  border: none !important;
}
